// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["qDNey6y4Q", "bvAJ2PL0b"];

const serializationHash = "framer-xmsno"

const variantClassNames = {bvAJ2PL0b: "framer-v-lyrq2o", qDNey6y4Q: "framer-v-u3zdl9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {bvAJ2PL0b: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}, default: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "qDNey6y4Q", "Variant 2": "bvAJ2PL0b"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, dL_1Zb_zC: title ?? props.dL_1Zb_zC ?? "/ Connect on linkedin", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "qDNey6y4Q"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dL_1Zb_zC, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "qDNey6y4Q", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEntermw1f1k = activeVariantCallback(async (...args) => {
setVariant("bvAJ2PL0b")
})

const onMouseLeave1s8e3a5 = activeVariantCallback(async (...args) => {
setVariant("qDNey6y4Q")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-u3zdl9", className, classNames)} data-framer-name={"Variant 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"qDNey6y4Q"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEntermw1f1k} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({bvAJ2PL0b: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave1s8e3a5}}, baseVariant, gestureVariant)}><motion.div className={"framer-12isfw0"} layoutDependency={layoutDependency} layoutId={"xNAbX6mTT"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW5zdHJ1bWVudCBTYW5zLTUwMA==", "--framer-font-family": "\"Instrument Sans\", \"Instrument Sans Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "500", "--framer-letter-spacing": "0px", "--framer-line-height": "23px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>/ Connect on linkedin</motion.p></React.Fragment>} className={"framer-3qbdhn"} data-framer-name={"/Connect with me."} fonts={["GF;Instrument Sans-500"]} layoutDependency={layoutDependency} layoutId={"Du2ZrjyST"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={dL_1Zb_zC} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-68822d"} layoutDependency={layoutDependency} layoutId={"yZTun7Ptd"} style={{backgroundColor: "rgb(255, 255, 255)"}}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-xmsno[data-border=\"true\"]::after, .framer-xmsno [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-xmsno.framer-a7mvid, .framer-xmsno .framer-a7mvid { display: block; }", ".framer-xmsno.framer-u3zdl9 { height: 28px; overflow: hidden; position: relative; width: 179px; }", ".framer-xmsno .framer-12isfw0 { align-content: flex-end; align-items: flex-end; bottom: 0px; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 0px; justify-content: center; left: -1px; overflow: visible; padding: 0px 0px 0px 0px; position: absolute; top: 0px; width: min-content; }", ".framer-xmsno .framer-3qbdhn { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-xmsno .framer-68822d { flex: none; height: 1px; overflow: visible; position: relative; width: 0px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-xmsno .framer-12isfw0 { gap: 0px; } .framer-xmsno .framer-12isfw0 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-xmsno .framer-12isfw0 > :first-child { margin-top: 0px; } .framer-xmsno .framer-12isfw0 > :last-child { margin-bottom: 0px; } }", ".framer-xmsno.framer-v-lyrq2o .framer-12isfw0 { right: 0px; width: unset; }", ".framer-xmsno.framer-v-lyrq2o .framer-68822d { width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 179
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"bvAJ2PL0b":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"dL_1Zb_zC":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Frameryn6O_T6qo: React.ComponentType<Props> = withCSS(Component, css, "framer-xmsno") as typeof Component;
export default Frameryn6O_T6qo;

Frameryn6O_T6qo.displayName = "Underline";

Frameryn6O_T6qo.defaultProps = {height: 28, width: 179};

addPropertyControls(Frameryn6O_T6qo, {variant: {options: ["qDNey6y4Q", "bvAJ2PL0b"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, dL_1Zb_zC: {defaultValue: "/ Connect on linkedin", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Frameryn6O_T6qo, [{family: "Instrument Sans", style: "normal", url: "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npST3-QfwmS0v3_7Y.woff2", weight: "500"}])